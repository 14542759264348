/**
 * @generated SignedSource<<24b1789c029669930a4846837e2fd8d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillAdvanceInput = {
  billId: string;
  clientMutationId?: string | null;
};
export type BillAdvanceMutation$variables = {
  input: BillAdvanceInput;
};
export type BillAdvanceMutation$data = {
  readonly billAdvance: {
    readonly bill: {
      readonly " $fragmentSpreads": FragmentRefs<"Account_bill">;
    };
  } | null;
};
export type BillAdvanceMutation = {
  response: BillAdvanceMutation$data;
  variables: BillAdvanceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillAdvanceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BillAdvancePayload",
        "kind": "LinkedField",
        "name": "billAdvance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bill",
            "kind": "LinkedField",
            "name": "bill",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Account_bill"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillAdvanceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BillAdvancePayload",
        "kind": "LinkedField",
        "name": "billAdvance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bill",
            "kind": "LinkedField",
            "name": "bill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amountInCents",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cadence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "120466aeea2f3f774ab8af52835038f0",
    "id": null,
    "metadata": {},
    "name": "BillAdvanceMutation",
    "operationKind": "mutation",
    "text": "mutation BillAdvanceMutation(\n  $input: BillAdvanceInput!\n) {\n  billAdvance(input: $input) {\n    bill {\n      ...Account_bill\n      id\n    }\n  }\n}\n\nfragment Account_bill on Bill {\n  id\n  name\n  amountInCents\n  cadence\n  dueDate\n}\n"
  }
};
})();

(node as any).hash = "bde412c9a1cc0bd50ea4a83e8c1f0a16";

export default node;
