/**
 * @generated SignedSource<<709604131e844140e164ac8cc6a7bfdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountsAccount_account$data = {
  readonly expectedBalanceInCents: number;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "AccountsAccount_account";
};
export type AccountsAccount_account$key = {
  readonly " $data"?: AccountsAccount_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountsAccount_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountsAccount_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedBalanceInCents",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e08255c8ab17eba62432d3caa74e42fd";

export default node;
