import { Card, CardBody, CardHeader, Heading, Paragraph } from "grommet";
import { Link } from "react-router-dom";
import { graphql, useFragment } from "react-relay";
import { AccountsAccount_account$key } from "./__generated__/AccountsAccount_account.graphql";

type Props = {
  account: AccountsAccount_account$key;
};

export default function Account(props: Props) {
  const account = useFragment(
    graphql`
      fragment AccountsAccount_account on Account {
        id
        name
        expectedBalanceInCents
      }
    `,
    props.account,
  );

  return (
    <Link to={`/accounts/${account.id}`}>
      <Card width="small" pad="small" margin="small">
        <CardHeader>
          <Heading level="2">{account.name}</Heading>
        </CardHeader>
        <CardBody>
          <Paragraph>
            Expected Balance: ${account.expectedBalanceInCents / 100}
          </Paragraph>
        </CardBody>
      </Card>
    </Link>
  );
}
