import { useState } from "react";
import {
  Box,
  Button,
  DateInput,
  Form,
  FormField,
  Heading,
  Layer,
  PageHeader,
  Paragraph,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TextInput,
} from "grommet";
import {
  useLazyLoadQuery,
  graphql,
  useFragment,
  useRefetchableFragment,
} from "react-relay";
import { useParams } from "react-router-dom";
import { AccountQuery } from "./__generated__/AccountQuery.graphql";
import useBillCreate, {
  type Variables as BillCreateVariables,
} from "src/mutations/BillCreate";
import useBillAdvance, {
  type Variables as BillAdvanceVariables,
} from "src/mutations/BillAdvance";
import { Account_bill$key } from "./__generated__/Account_bill.graphql";
import { AccountRefreshQuery } from "./__generated__/AccountRefreshQuery.graphql";
import { Account_account$key } from "./__generated__/Account_account.graphql";

export default function Account() {
  const [show, setShow] = useState<boolean>(false);
  const params = useParams();
  const query = useLazyLoadQuery<AccountQuery>(
    graphql`
      query AccountQuery($id: ID!) {
        account(id: $id) {
          ...Account_account
        }
      }
    `,
    { id: params.id },
  );

  const [account, refetch] = useRefetchableFragment<
    AccountRefreshQuery,
    Account_account$key
  >(
    graphql`
      fragment Account_account on Account
      @refetchable(queryName: "AccountRefreshQuery") {
        name
        expectedBalanceInCents
        totalAmountInCents
        totalAmountInCentsPerPayPeriod
        bills {
          ...Account_bill
        }
      }
    `,
    query.account,
  );

  return (
    <>
      <CreateBillModal
        accountId={params.id}
        open={show}
        onCancel={() => setShow(false)}
        onComplete={() => {
          refetch({}, { fetchPolicy: "network-only" });
          setShow(false);
        }}
      />
      <Box direction="column" pad="medium">
        <PageHeader title={account.name} subtitle="Account" />
        <Box direction="row" justify="between">
          <Paragraph>
            Expected Balance: ${account.expectedBalanceInCents / 100}
          </Paragraph>
          <Paragraph>
            Total Bill Amount: ${account.totalAmountInCents / 100}
          </Paragraph>
          <Paragraph>
            Total Bill Amount Per Pay: $
            {account.totalAmountInCentsPerPayPeriod / 100}
          </Paragraph>
        </Box>
        <Box
          direction="row"
          alignContent="center"
          justify="between"
          flex="shrink"
        >
          <Heading level="2">Bills</Heading>
          <Box direction="column" justify="center">
            <Button
              onClick={() => setShow(true)}
              label="Add a bill"
              fill={false}
            />
          </Box>
        </Box>

        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                <strong>Name</strong>
              </TableCell>
              <TableCell scope="col" border="bottom">
                <strong>Amount</strong>
              </TableCell>
              <TableCell scope="col" border="bottom">
                <strong>Cadence</strong>
              </TableCell>
              <TableCell scope="col" border="bottom">
                <strong>Due Date</strong>
              </TableCell>
              <TableCell scope="col" border="bottom" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {account.bills.map((bill) => (
              <BillRow bill={bill} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

function BillRow(props: { bill: Account_bill$key }) {
  const [advance, advancing] = useBillAdvance();
  const bill = useFragment(
    graphql`
      fragment Account_bill on Bill {
        id
        name
        amountInCents
        cadence
        dueDate
      }
    `,
    props.bill,
  );

  return (
    <TableRow>
      <TableCell scope="row">{bill.name}</TableCell>
      <TableCell scope="row">${bill.amountInCents / 100}</TableCell>
      <TableCell scope="row">{bill.cadence}</TableCell>
      <TableCell scope="row">{new Date(bill.dueDate).toString()}</TableCell>
      <TableCell scope="row">
        <Button
          onClick={() => advance({ variables: { input: { billId: bill.id } } })}
          label="Mark Paid"
          disabled={advancing}
        />
      </TableCell>
    </TableRow>
  );
}

type FormValues = Omit<
  Omit<BillCreateVariables, "accountId">,
  "amountInCents"
> & { amount: string };

const DEFAULT_FORM_VALUES: FormValues = {
  name: null,
  amount: null,
  cadence: "MONTHLY",
  dueDate: null,
};

function CreateBillModal({
  accountId,
  open,
  onComplete,
  onCancel,
}: {
  accountId: string;
  open: boolean;
  onComplete: () => unknown;
  onCancel: () => unknown;
}) {
  const [createBill] = useBillCreate();
  const [value, setValue] = useState<FormValues>(DEFAULT_FORM_VALUES);

  if (!open) {
    return null;
  }

  return (
    <Layer modal onEsc={onCancel} onClickOutside={onCancel}>
      <Box pad="medium">
        <Form
          value={value}
          onChange={(nextValue) => setValue(nextValue)}
          onReset={() => setValue(DEFAULT_FORM_VALUES)}
          onSubmit={({ value: { amount, ...value } }) => {
            createBill({
              variables: {
                input: {
                  ...value,
                  accountId,
                  amountInCents: parseFloat(amount) * 100,
                },
              },
              onCompleted: () => {
                setValue(DEFAULT_FORM_VALUES);
                onComplete();
              },
            });
          }}
        >
          <FormField name="name" htmlFor="name-input" label="Name">
            <TextInput id="name-input" name="name" />
          </FormField>

          <FormField name="amount" htmlFor="amount-input" label="Amount">
            <TextInput id="amount-input" name="amount" type="number" />
          </FormField>

          <FormField name="cadence" htmlFor="cadence-select" label="Cadence">
            <Select
              name="cadence"
              id="cadence-select"
              options={[
                "ANNUALLY",
                "FORTNIGHTLY",
                "MONTHLY",
                "QUARTERLY",
                "WEEKLY",
              ]}
              labelKey={(value) =>
                value.substring(0, 1) + value.substring(1).toLowerCase()
              }
            ></Select>
          </FormField>

          <FormField name="dueDate" htmlFor="dueDate-input" label="Due Date">
            <DateInput format="dd/mm/yyyy" name="dueDate" id="dueDate-input" />
          </FormField>

          <Box direction="row" gap="medium">
            <Button type="submit" primary label="Submit" />
            <Button type="reset" label="Reset" />
          </Box>
        </Form>
      </Box>
    </Layer>
  );
}
