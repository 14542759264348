/**
 * @generated SignedSource<<5e33e9a3a5052cd57c31eb98871245e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountCreateInput = {
  clientMutationId?: string | null;
  name: string;
};
export type AccountCreateMutation$variables = {
  input: AccountCreateInput;
};
export type AccountCreateMutation$data = {
  readonly accountCreate: {
    readonly account: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"Account_account">;
    };
  } | null;
};
export type AccountCreateMutation$rawResponse = {
  readonly accountCreate: {
    readonly account: {
      readonly bills: ReadonlyArray<{
        readonly amountInCents: number;
        readonly cadence: string;
        readonly dueDate: any;
        readonly id: string;
        readonly name: string;
      }>;
      readonly expectedBalanceInCents: number;
      readonly id: string;
      readonly name: string | null;
      readonly totalAmountInCents: number;
      readonly totalAmountInCentsPerPayPeriod: number;
    };
  } | null;
};
export type AccountCreateMutation = {
  rawResponse: AccountCreateMutation$rawResponse;
  response: AccountCreateMutation$data;
  variables: AccountCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountCreatePayload",
        "kind": "LinkedField",
        "name": "accountCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Account_account"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountCreatePayload",
        "kind": "LinkedField",
        "name": "accountCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expectedBalanceInCents",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalAmountInCents",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalAmountInCentsPerPayPeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Bill",
                "kind": "LinkedField",
                "name": "bills",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountInCents",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cadence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dueDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5dbf7d38eb789454ed6ea42a962c038",
    "id": null,
    "metadata": {},
    "name": "AccountCreateMutation",
    "operationKind": "mutation",
    "text": "mutation AccountCreateMutation(\n  $input: AccountCreateInput!\n) {\n  accountCreate(input: $input) {\n    account {\n      id\n      ...Account_account\n    }\n  }\n}\n\nfragment Account_account on Account {\n  name\n  expectedBalanceInCents\n  totalAmountInCents\n  totalAmountInCentsPerPayPeriod\n  bills {\n    ...Account_bill\n    id\n  }\n  id\n}\n\nfragment Account_bill on Bill {\n  id\n  name\n  amountInCents\n  cadence\n  dueDate\n}\n"
  }
};
})();

(node as any).hash = "2447361a612c65134a3d21e754bbee97";

export default node;
