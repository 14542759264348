import { Environment, Network, RecordSource, Store } from "relay-runtime";

const URL =
  process.env.NODE_ENV === "production"
    ? "https://api.bills.app.dbs.pub"
    : "http://localhost:5000";

const network = Network.create(async (operation, variables) => {
  return fetch(`${URL}/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => {
    return response.json();
  });
});

const environment = new Environment({
  network,
  store: new Store(new RecordSource()),
});

export default environment;
