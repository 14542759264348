/**
 * @generated SignedSource<<7508c353b413439995aee19b4c4fceaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Account_account$data = {
  readonly bills: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"Account_bill">;
  }>;
  readonly expectedBalanceInCents: number;
  readonly id: string;
  readonly name: string | null;
  readonly totalAmountInCents: number;
  readonly totalAmountInCentsPerPayPeriod: number;
  readonly " $fragmentType": "Account_account";
};
export type Account_account$key = {
  readonly " $data"?: Account_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"Account_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./AccountRefreshQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "Account_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedBalanceInCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmountInCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmountInCentsPerPayPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Bill",
      "kind": "LinkedField",
      "name": "bills",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Account_bill"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "a4e74c6931b2e08c24a71879bcd2e6f6";

export default node;
