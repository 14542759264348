import { useCallback, useEffect, useState } from "react";
import {
  graphql,
  type PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { type AccountsQuery } from "./__generated__/AccountsQuery.graphql";
import Account from "./Account";
import {
  Box,
  Button,
  Card,
  CardBody,
  Form,
  FormField,
  Header,
  Heading,
  Layer,
  TextInput,
} from "grommet";
import { Add } from "grommet-icons";
import useAccountCreate from "src/mutations/AccountCreate";

const Query = graphql`
  query AccountsQuery {
    accounts {
      id
      ...AccountsAccount_account
    }
  }
`;

export default function Container() {
  const [ref, loadQuery] = useQueryLoader<AccountsQuery>(Query);

  useEffect(() => {
    if (ref == null) {
      loadQuery({});
    }
  }, [ref]);

  const refetch = useCallback(() => {
    loadQuery({}, { fetchPolicy: "network-only" });
  }, []);

  if (ref == null) {
    return null;
  }

  return <Accounts queryRef={ref} refetch={refetch} />;
}

export function Accounts({
  queryRef,
  refetch,
}: {
  queryRef: PreloadedQuery<AccountsQuery>;
  refetch: () => unknown;
}) {
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState({ name: "" });
  const [createAccount] = useAccountCreate();
  const { accounts } = usePreloadedQuery<AccountsQuery>(Query, queryRef);

  return (
    <>
      <Box fill>
        <Header>
          <Heading color="brand">Bill Smoother</Heading>
        </Header>

        <Box direction="row" wrap>
          {accounts.map((account) => (
            <Account key={account.id} account={account} />
          ))}
          <Card
            width="small"
            pad="small"
            margin="small"
            onClick={() => setShow(true)}
            role="button"
            hoverIndicator
          >
            <CardBody justify="center" align="center">
              <Add color="brand" />
            </CardBody>
          </Card>
        </Box>
      </Box>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <Box pad="medium">
            <Form
              value={value}
              onChange={(nextValue) => setValue(nextValue)}
              onReset={() => setValue({ name: "" })}
              onSubmit={({ value }) => {
                createAccount({
                  variables: { input: { name: value.name } },
                  onCompleted: refetch,
                });
                setShow(false);
              }}
            >
              <FormField name="name" htmlFor="name-input" label="Name">
                <TextInput id="name-input" name="name" />
              </FormField>
              <Box direction="row" gap="medium">
                <Button type="submit" primary label="Submit" />
                <Button type="reset" label="Reset" />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
    </>
  );
}
