import { graphql, useMutation, VariablesOf } from "react-relay";
import { type BillCreateMutation } from "./__generated__/BillCreateMutation.graphql";

export type Variables = VariablesOf<BillCreateMutation>["input"];

export default function useBillCreate() {
  return useMutation<BillCreateMutation>(graphql`
    mutation BillCreateMutation($input: BillCreateInput!) {
      billCreate(input: $input) {
        bill {
          amountInCents
        }
      }
    }
  `);
}
