import { graphql, useMutation, VariablesOf } from "react-relay";
import { type BillAdvanceMutation } from "./__generated__/BillAdvanceMutation.graphql";

export type Variables = VariablesOf<BillAdvanceMutation>["input"];

export default function useBillAdvance() {
  return useMutation<BillAdvanceMutation>(graphql`
    mutation BillAdvanceMutation($input: BillAdvanceInput!) {
      billAdvance(input: $input) {
        bill {
          ...Account_bill
        }
      }
    }
  `);
}
