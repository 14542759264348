import { Suspense } from "react";
import * as ReactDOM from "react-dom/client";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { Grommet } from "grommet";
import environment from "src/lib/relay";
import Account from "src/screens/Account";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Accounts from "src/screens/Accounts";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Grommet full>
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback="Loading...">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/accounts" />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/accounts/:id" element={<Account />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </RelayEnvironmentProvider>
  </Grommet>,
);
