import { graphql, useMutation } from "react-relay";
import { type AccountCreateMutation } from "./__generated__/AccountCreateMutation.graphql";

export default function useAccountCreate() {
  const [commit, inFlight] = useMutation<AccountCreateMutation>(graphql`
    mutation AccountCreateMutation($input: AccountCreateInput!)
    @raw_response_type {
      accountCreate(input: $input) {
        account {
          id
          ...Account_account
        }
      }
    }
  `);

  return [commit, inFlight] as const;
}
